<template>
<div style="min-width:100%;min-height:100%;background:#E6E6E6">
   <div class="container">
      <div class="p-md-5 p-md-2 p-sm-1">
      
        
       <div class="row justify-content-center mt-5 p-3" >
           <div class="col-12 col-sm-10 col-md-6 col-lg-5 position-relative" style="box-shadow: 0px 0px 2px 2px grey;
    padding: 15px; border-radius: 15px;background: #fff;z-index: 1;
    overflow: hidden;">
               <img class="position-absolute" src="@/assets/india/image/bookdemo.png" style="left: -96px;
    z-index: -1;
    top: -70px;
    width: 212px;" />
        <h1 class="text-center">
               <a href="https://code.tinker.ly" target="_blank">
            
              <img
                src="@/assets/india/image/logo.png"
                class="px-4 py-2 img-fluid"
                style="
                  border: 1px solid black;
                  border-radius: 10rem;
                  max-width: 180px;
                  width: 55%;
                  min-width: 100px;
                "
              />
              
              </a>
        </h1>
        <h4 class="text-center fw-bold m-3" style="color:#FF9933;font-size: calc(0.5rem + 0.7vw);">
            <span style="border-bottom: 3px solid #E6E6E6;line-height: 35px;">احجز تجربتك المجانية الآن, المقاعد محدودة!</span>
        </h4>
          <p v-if="pageError" class="text-danger text-center">
                        {{ pageError }}
                      </p>
               <Form class="mt-5"  @submit="onSubmit" v-slot="{ values }" :validation-schema="schema">
                 <div v-if="!otpVerify">
                   <div class="form-group mb-3">
                       <label class="text-muted">رقم جوال الوالد\ة</label>
                        <vue-tel-input style="height:45px;" @close="MobileNumberfocus"  @input ="MobileNumberChange" @focus="MobileNumberfocus"  mode="international" :autoDefaultCountry=false :defaultCountry="$store.state.country" :inputOptions="Mobileopt" validCharactersOnly=true>

                        </vue-tel-input>
                      
                           <p v-if="!ismobilevalid && ismobileFocus" class="text-danger" name="Mobile">Mobile number is not valid</p>
                   </div>
                   <div class="form-group mb-3">
                       <label class="text-muted">اسم الطالب</label>
                       <Field type="text" class="form-control" name="Name" placeholder="Student Name"/>
                           <ErrorMessage class="text-danger" name="Name" />
                   </div>
                    <div class="form-group mb-3">
                  <label class="text-muted">عنوان البريد الإلكتروني للوالدين</label>
                  <Field
                    type="text"
                    class="form-control"
                    name="Email"
                    placeholder="Email"
                  />
                  <ErrorMessage class="text-danger" name="Email" />
                </div>
                   <div class="form-group mb-3" v-if="cnt=='IN'">
                                              <label class="text-muted">Student Grade/Class:</label>
                                                <div class="radio-toolbar">
                          <div style="display: inline;" v-for="index in 12" :key="index" >
                                <Field type="radio" :id="'grade_'+index" name="Grade" :value="index"/>
                            <label :for="'grade_'+index">{{index}}</label>
                          </div>

                        </div>
                        <ErrorMessage class="text-danger" name="Grade" />
                   </div>
                  <div class="form-group mb-3" v-else>
                       <label class="text-muted">عمر الطالب</label>
                        <div class="radio-toolbar">
   <div style="display: inline;" v-for="index in 11" :key="index" >
        <Field type="radio" :id="'grade_'+(index+5)" name="Grade" :value="(index+5)"/>
    <label :for="'grade_'+(index+5)">{{(index+5)}}</label>
   </div>

</div>
<ErrorMessage class="text-danger" name="Grade" />
                   </div>
                 </div>
                 <div v-else-if="otpVerify && !getClass">
                <div class="form-group mb-3">
                  <label class="text-muted"
                    >هل لديك لابتوب\كمبيوتر في منزلك لحضور الدورة؟</label
                  >
                  <div class="radio-toolbar">
                    <div style="display: inline">
                      <Field
                        type="radio"
                        id="device_yes"
                        name="Device"
                        value="true"
                      />
                      <label for="device_yes">نعم</label>
                    </div>
                    <div style="display: inline">
                      <Field
                        type="radio"
                        id="device_no"
                        name="Device"
                        value="false"
                      />
                      <label for="device_no">لا</label>
                    </div>
                  </div>
                  <ErrorMessage class="text-danger" name="Device" />
                </div>
                <div class="form-group mb-3" v-if='loginData.variants.length>1'>
                  <label class="text-muted">Choose your class type:</label>
                  <div class="radio-toolbar">
                    <div
                      style="display: inline"
                      v-for="variant in loginData.variants"
                      :key="variant.id"
                    >
                      <Field
                        type="radio"
                        :id="'class_' + variant.id"
                        name="ClassType"
                        :value="variant.id"
                      />
                      <label :for="'class_' + variant.id">{{
                        variant.display
                      }}</label>
                    </div>
                  </div>
                  <ErrorMessage class="text-danger" name="ClassType" />
                </div>
                <div class="form-group mb-3" v-if="cnt == 'IN'">
                  <label class="text-muted">Choose your course language:</label>
                  <div class="radio-toolbar">
                    <div style="display: inline">
                      <Field
                        type="checkbox"
                        id="language_hindi"
                        name="Language"
                        value="Hindi"
                      />
                      <label for="language_hindi">Hindi</label>
                    </div>
                    <div style="display: inline">
                      <Field
                        type="checkbox"
                        id="language_english"
                        name="Language"
                        value="English"
                      />
                      <label for="language_english">English</label>
                    </div>
                  </div>
                  <ErrorMessage class="text-danger" name="Language" />
                </div>
                  <p v-if="noClass" class="text-danger text-center">
                        {{ noClass }}
                      </p>
              </div>
              <div v-else-if="getClass">
                <div class="form-group mb-3">
                  <label class="text-muted">اختر التاريخ المناسب:</label>
                  <div class="radio-toolbar">
                    <div
                      style="display: inline"
                      v-for="date in availableClass"
                      :key="date"
                    >
                      <Field
                        type="radio"
                        :id="'date_' + date.date"
                        name="Date"
                        :value="date"
                      />
                      <label :for="'date_' + date.date" v-html="getDateFromString(date.date)"></label>
                    </div>
                  </div>
                  <ErrorMessage class="text-danger" name="Date" />
                </div>
                                <div class="form-group mb-3" v-if="values.Date!=null">
                  <label class="text-muted">اختر الوقت المناسب:</label>
                  <div class="radio-toolbar">
                    <div
                      style="display: inline"
                      v-for="time in values.Date.time"
                      :key="time.id+time.start"
                    >
                      <Field
                        type="radio"
                        :id="'time_' + time.id+time.start"
                        name="Time"
                        :value="time"
                      />
                      <label :for="'time_' + time.id+time.start" >
                        {{time.start+' - '+time.end}}</label>
                    </div>
                  </div>
                  <ErrorMessage class="text-danger" name="Time" />
                </div>
                </div>
                   <button type="submit" style="height:45px" class="btn  btn_orange_active">{{buttonText}}</button>
                   <!--By registering here, I agree to Tinkerly <a target="_Blank" href="https://tinker.ly/terms-and-conditions/">Terms & Conditions</a> and <a target="_Blank" href="https://tinker.ly/privacy-policy-for-tinkerly/">Privacy Policy</a> -->
                   <p class="mt-3" style="font-size: 0.8rem;">بالتسجيل هنا, أوافق على شروط وأحكام وسياسة خصوصية تينكرلي</p>
                   <div class="modal " v-if="sendOtp" style="display:block;background:rgba(0,0,0,0.8)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
      <p  style="text-align: right;font-size: 2rem;color:#D1D1D1;">
          <a style="cursor: pointer;" @click="HideOtpDialog()" >&times;</a></p>
      <h4 class="text-center m-0">أدخل الرمز</h4>
      <p class="text-center" style="color:#D1D1D1">تم إرسال الرمز إلى {{Mobile}}</p>
      <p class="text-center m-0"> 
          <input  class="partitioned text-center" v-model="otp1" v-mask="'#'"  v-on:keyup="gonext(0,$event)"   type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp2" v-mask="'#'" v-on:keyup="gonext(1,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp3" v-mask="'#'"  v-on:keyup="gonext(2,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp4" v-mask="'#'" v-on:keyup="gonext(3,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp5" v-mask="'#'"  v-on:keyup="gonext(4,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp6" v-mask="'#'" v-on:keyup="gonext(5,$event)"  type="text" maxlength="1" />
          </p>
           <p v-if="otperror" class="text-danger text-center">{{otperror}}</p>
      <p class="text-center m-0">لم تستلم الرسالة؟</p>
     <p class="text-center m-0"><button  type="button" @click="resendOtp" class="btn btn-link">إعادة إرسال</button></p>
     <p class="text-center"><button type="button" @click="submitOtp" style="height:45px" class="btn  btn_orange_active">إدخال</button></p>
      </div>
     
    </div>
  </div>
</div>
               </Form >
           </div>
       </div>
      
       <div class="modal " v-if="isLoading" style="display:block;background:rgba(0,0,0,0.8)">
  <div class="modal-dialog modal-dialog-centered" style="height: 85px !important;width: 85px !important;" role="document">
    <div class="modal-content">
      <div class="modal-body">
     <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
      </div>
     
    </div>
  </div>
</div>
<div
          class="modal"
          v-if="bookSuccess"
          style="display: block; background: rgba(0, 0, 0, 0.8)"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body" style="text-align: center">
                <strong>Congratulations </strong><br/>
You have successfully booked a demo on {{form.last.Date.date}} and {{form.last.Time.start}}<br/>
{{remainSec}} Sec to redirect...
              </div>
            </div>
          </div>
        </div>
    </div>
   </div>
    </div>
</template>
<script>
const leadOptions = {headers: {'Content-Type': 'application/json'}};
 const leadURL = "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r33f4bf885fac45bf9fd2cb770d2cb54e&secretKey=63c3b1df9c6a723e3dd5bdc663b27b8f6aca7158";
   var json_for_demo='';
   // import '../assets/css/bootstrap.css'
    import '@/assets/css/rtl.bootstrap.min.css'
   import '@/assets/Font/rtl.css'
    import {mask} from 'vue-the-mask'
    import { Form, Field, ErrorMessage } from 'vee-validate';
   import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import * as yup from "yup";
import * as moment from 'moment';
import {getMXCProspectId,trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
    export default {
        name: 'BookDemo',
         directives: {mask},
          components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput
  },
  data() {
     const schema = yup.object({
      Name: yup.string().when('otp',{
        is: ()=> !this.otpVerify,
         then: yup.string().required()
      }),
       Grade: yup.number().when('otp',{
        is: ()=> !this.otpVerify,
          then:yup.number().required()
      }),
       Email: yup.string().when('otp',{
        is: ()=> !this.otpVerify && this.cnt!='IN',
         then: yup.string().email().required(),
      }),
      Device:yup.boolean().when('otp',{
        is: ()=> (this.otpVerify && !this.getClass),
         then: yup.boolean().required()
      }),
      ClassType:yup.number(),
      Language:yup.array().when('otp', {
        is: ()=>(this.otpVerify && this.cnt=='IN' && !this.getClass),
         then: yup.array().of(yup.string()).min(1).required()
      }),
       Date:yup.object().when('otp', {
        is: ()=>(this.getClass),
         then: yup.object().required()
      }),
       Time:yup.object().when('otp', {
        is: ()=>(this.getClass),
         then: yup.object().required()
      })
    });
    return {
           schema,
      buttonText:'ارسال كود تفعيل',
      cnt: "IN",
      remainSec:3,
      bookSuccess:false,
      sendOtp: false,
      form: null,
      otpVerify: false,
      getClass:false,
      loginData: null,
      availableClass:null,
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:'',
      otp5:'',
      otp6:'',
      otperror:'',
      pageError:'',
      isLoading:false,
      otpenc :'',
      Mobile:'',
      ismobilevalid:true,
      ismobileFocus:false,
      Mobileopt:{
        id:"Mobile",
        name : "Mobile",
        maxlength:18,
        required:true,
        placeholder:"Mobile Number"
      }
    };
  },
  methods: {
     getDateFromString(date){
     return moment(date, "DD-MM-YYYY").format('ddd[<br/><strong>]DD MMM[</strong>]'); 
    },
    MobileNumberChange(num,data) {
      if(this.ismobileFocus){
      this.ismobilevalid = data.valid;
      if(data.valid === true)
      this.Mobile = "+"+data.countryCallingCode+"-"+data.nationalNumber;
      }
     },
     MobileNumberfocus(){
       this.ismobileFocus = true;
       if(this.Mobile.length<5 && this.ismobilevalid)
       this.ismobilevalid = false;
     },
    onSubmit(values) {
      var bodyForm ;
      if(this.getClass){
         this.isLoading = true;
         this.form.last = values;
        bodyForm = new FormData();
        bodyForm.append("Token", this.loginData.token);
      bodyForm.append("Id", this.loginData.id);
      bodyForm.append("Json", json_for_demo);
      bodyForm.append("SessionVarientId", this.form.ClassType);
      bodyForm.append("Tokentype", 1);
      var d = values.Date.date.split('-');
      bodyForm.append("Time", d[2]+'-'+d[1]+'-'+d[0]+' '+values.Time.start+':00.000');
       this.axios
        .post("https://lt.tinker.ly/api/v1/BookSessionDemo", bodyForm)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.bookSuccess = true;
            trackerFBPixel('Scheduled');
          this.$gtag.event("conversion", {
              send_to: "AW-810557807/AFA9CMbNgYYDEO_CwIID",
            });
          let intervalTimerId = setInterval(() => this.remainSec--, 1000); 
          // after 5 seconds stop
          setTimeout(() => {
            clearInterval(intervalTimerId);
             window.location.href = "https://alpha.letstinker.tinker.ly/bypass_login?id="+data.data;
          }, 3000);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.pageError = error.response.data.data;
          this.isLoading = false;
        });
        return;
      }
      else if(this.otpVerify && !this.getClass){
        this.form = values;
        this.isLoading = true;
        bodyForm = new FormData();
       if(values.Language == undefined)
        json_for_demo = '[{"key":"device","items":[{"label":"Laptop","value":'+values.Device+'}]},'+
        '{"key":"lang","items":[{"label":"Hindi","value":false},'+
        '{"label":"English","value":true}]}]';
        else
        json_for_demo = '[{"key":"device","items":[{"label":"Laptop","value":'+values.Device+'}]},'+
        '{"key":"lang","items":[{"label":"Hindi","value":'+(values.Language.indexOf('Hindi')>-1)+'},'+
        '{"label":"English","value":'+(values.Language.indexOf('English')>-1)+'}]}]';
        if(this.form.ClassType==undefined)
          this.form.ClassType = this.loginData.variants[0].id;
      bodyForm.append("Token", this.loginData.token);
      bodyForm.append("Type", 0);
      bodyForm.append("Id", this.loginData.id);
      bodyForm.append("Json", json_for_demo);
      bodyForm.append("SessionVarientId", this.form.ClassType);
      bodyForm.append("OTPVerify", true);
      bodyForm.append("Tokentype", 1);
      this.axios
        .post("https://lt.tinker.ly/api/v1/GetAvailableslot", bodyForm)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if(data.data.length<1){
            this.noClass = 'No class available.'
            this.isLoading = false;
            return;
          }
            trackerFBPixel('Calender');
          this.$gtag.event("conversion", {
              send_to: "AW-810557807/zAU9CIC2-IUDEO_CwIID",
            });
          this.buttonText = 'احجز الآن'
          this.getClass = true;
          this.availableClass = data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.pageError = error.response.data.data;
          this.isLoading = false;
        });
      return;
      }
      else if(!this.ismobileFocus || !this.ismobilevalid || this.Mobile.length<8 )
      {
        this.ismobilevalid = false;
        this.ismobileFocus = true;
        return;
      }
        this.form = values;
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        if(this.form.Email != undefined)
        bodyFormData.append("Email", this.form.Email);
        this.axios.post('https://lt.tinker.ly/api/V1/SendOtpEnc',bodyFormData).
        then((response) => {
                 return response.data
                        
            })
            .then(data => {
              this.otpenc = data.data;
          this.sendOtp = true;
          this.isLoading = false;
          var d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":this.form.Name},
         {"Attribute":"mx_Kids_Age","Value":this.form.Grade},
         {"Attribute":"mx_otp_verify","Value":"No"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'},
        { "Attribute": "ProspectStage", Value: 'Prospect' },
        { "Attribute": "mx_Grade_Type", Value: 'Age' }];
 let {utm_source,utm_medium,utm_campaign} = this.$route.query;
             if(utm_source !=null)
             d.push({ Attribute: "Source", Value: utm_source });
             if(utm_campaign !=null)
             d.push({ Attribute: "SourceCampaign", Value: utm_campaign });
             if( utm_medium!=null)
             d.push({ Attribute: "SourceMedium", Value: utm_medium });
          if(this.form.Email != undefined)
           d.push({ Attribute: "EmailAddress", Value: this.form.Email });
           if(getMXCProspectId()!=null)
           d.push({ Attribute: "ProspectID", Value: getMXCProspectId() });

     this.axios.post(leadURL,d,leadOptions);
  })
  .catch(error => {
     console.error(error);
     this.pageError = error.response.data.data;
    this.isLoading = false;
  });
     
    },
    gonext(e,event){
       var alls = document.getElementsByClassName("partitioned");
       if(event.target.value.trim().length == 0  && e==0){
           alls[0].focus();
           alls[0].select();
       }

       else if(event.target.value.trim().length == 0 && (event.key=="Backspace" || event.key=="Delete") && e>0)
        {
            alls[e-1].focus();
             alls[e-1].select();
        }
        else if(event.target.value.trim().length >0 && e<5){
            alls[e+1].focus();
             alls[e+1].select();
        }
    },
    
    resendOtp(){
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
         if(this.form.Email != undefined)
        bodyFormData.append("Email", this.form.Email);
        this.axios.post('https://lt.tinker.ly/api/V1/SendOtpEnc',bodyFormData).
        then((response) => {
                 return response.data
                        
            })
            .then(data => { 
                trackerFBPixel('OTP');
          this.$gtag.event("conversion", {
              send_to: "AW-810557807/b1_1CJGaz4UDEO_CwIID",
            });
              this.otpenc = data.data;
      this.sendOtp = true;
      this.isLoading = false;
  })
  .catch(error => {
     console.error(error);
     this.pageError = error.response.data.data;
    this.isLoading = false;
  });
    
     
    },
    submitOtp(){
        
        var otp = ""+this.otp1+this.otp2+this.otp3+this.otp4+this.otp5+this.otp6;
        if(otp.length!=6)
        this.otperror="Please enter a valid OTP";
        else{
            this.otperror="";
            this.isLoading = true;
             var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        bodyFormData.append('Name', this.form.Name);
        bodyFormData.append('Auth', this.otpenc);
        bodyFormData.append('Otp', otp);
        bodyFormData.append('Grade', this.form.Grade);
        bodyFormData.append('Operation', 1);
        bodyFormData.append('CCode',this.cnt);
        bodyFormData.append('Refer',this.$route.params.username );
        if(this.form.Email != undefined)
        bodyFormData.append("Email", this.form.Email);
             this.axios.post('https://lt.tinker.ly/api/V1/LoginViaCode',bodyFormData).
             then((response) => {
                 return response.data   
            })
            .then(data => {
               trackerFBPixel('Lead');
             this.$gtag.event("conversion", {
              send_to: "AW-810557807/LzzVCKn0-IUDEO_CwIID",
            });
              this.loginData = data.data;
            this.otpVerify = true;
            this.buttonText = 'تقويم الحجز'
            this.sendOtp = false;
            this.isLoading = false;
              this.$gtag.event('conversion', {
          'send_to': 'AW-810557807/-D1ECN7mt_kCEO_CwIID'
      });
                var d = [{"Attribute":"Phone","Value":this.Mobile},
         {"Attribute":"FirstName","Value":this.form.Name},
         {"Attribute":"mx_Kids_Age","Value":this.form.Grade},
         {"Attribute":"mx_otp_verify","Value":"Yes"},
         {"Attribute":"mx_Lead_Type","Value":'Int. User'},
         {"Attribute":"Source","Value":'Web-SA-ar'},
        { "Attribute": "ProspectStage", Value: 'Prospect' },
        { "Attribute": "mx_Grade_Type", Value: 'Age' }];

          if(this.form.Email != undefined)
           d.push({ Attribute: "EmailAddress", Value: this.form.Email });
           if(getMXCProspectId()!=null)
           d.push({ Attribute: "ProspectID", Value: getMXCProspectId() });
    this.axios.post(leadURL, d, leadOptions).then(() => {});
  })
  .catch(error => {
      this.isLoading = false;
      if(error.response.data.status.code==301)
      this.otperror="Invalid OTP";
  });
        }
    }
,
  HideOtpDialog(){
          this.sendOtp = false;
            this.otp1='';
            this.otp2='';
            this.otp3='';
            this.otp4='';
            this.otp5='';
            this.otp6='';
    }
  },
    beforeMount: function () {
       this.cnt = this.$store.state.country;
        
    },
  mounted: function(){
   trackerFBPixel('Demo');
     this.$gtag.event("conversion", {
              send_to: "AW-810557807/_6ZMCPOZz4UDEO_CwIID",
            });
  window.__lc = window.__lc || {};
  window.__lc.license = 13188978;
  (function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}
  var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){
  i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
  get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
  return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){
  var n=t.createElement("script");
  n.async=!0,n.type="text/javascript",
  n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};
  !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    }
};
</script>
<style scoped>
.vti__dropdown-list{
  left: auto;
  text-align: right;
}
.partitioned {
  padding: 10px 15px;
  border: 2px solid #D1D1D1;
  border-radius: 5px;
  margin: 10px;
  width: 45px;
}
input.partitioned:focus{
    outline: none;
    border: 2px solid #FF9933;
}
.btn-check:focus + .btn, .btn:focus{
    box-shadow: none;
}
.btn_orange_active {
        background: #FF9933;
        color:#fff;
        border:2px #FF6633 solid;
    }
    * {
       font-family: pnu;  
    }
    .form-control:focus{
        box-shadow: none;
    }
  .form-control{
      height: 45px;
      border: 2px solid #D1D1D1;
  }
    

.radio-toolbar input[type="radio"],
.radio-toolbar input[type="checkbox"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  padding: 10px 10px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  color: #000;
  border: 2px solid #d1d1d1;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  min-height: 45px;
  margin: 10px;
  min-width: 45px;
  text-align: center;
}

.radio-toolbar label:hover {
  background-color: #ff9933;
  border-color: #ff6633;
}

.radio-toolbar input[type="radio"]:checked + label,
.radio-toolbar input[type="checkbox"]:checked + label {
  background-color: #ff9933;
  border-color: #ff6633;
}
</style>
